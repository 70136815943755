<template>
  <el-main class="rh-table">
    <h1>Formations prégraduées</h1>
    <app-table
      tablenom="fpregrads"
      :cols="colonnes"
      :items="fpregrads"
      :loadstatus="fpregradsLoadStatus"
      :deletestatus="fpregradDeleteStatus"
      :formroutes="fprFormRoutes"
      :actions="fprActions"
    >
    </app-table>
  </el-main>
</template>
<script>
import AppTable from "@/components/rh/ui/table/AppTable";

export default {
  components: { "app-table": AppTable },
  data() {
    return {
      idMed: "",
      fprFormRoutes: {
        edit: { route: "fpregradedit", params: { idfpr: "id" } },
        create: { route: "fpregradcreate" },
      },
    };
  },
  computed: {
    fpregradsLoadStatus() {
      // 1 = loading, 2 = succès, 3 = échec du chargement des données
      return this.$store.state.fpr.fpregradsLoadStatus;
    },
    fpregradDeleteStatus() {
      // 1 = deleting, 2 = succès, 3 = échec
      return this.$store.state.fpr.fpregradDeleteStatus;
    },
    fpregrads() {
      return this.$store.state.fpr.fpregrads;
    },
    colonnes() {
      return this.$store.state.fpr.colonnes;
    },
    fprActions() {
      return this.$store.state.fpr.actions;
    },
  },
};
</script>
